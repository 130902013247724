export default {
  //statics
  title: "Başlık",
  rate_name: "Oran",
  reservations: "Rezervasyonlar",
  turkish: "Türkçe",
  english: "English",
  new_reservation: "Yeni Rezervasyon",
  search: "Ara",
  pickup_location: "Alış Lokasyon",
  pickup_time: "Alış Zamanı",
  promo_code: "Promosyon Kodu",
  return_location: "İade Lokasyon",
  return_time: "İade Zamanı",
  vendor_daily_price: "Tedarikçi Günlük Fiyat",
  rental_daily_price: "Müşteri Günlük Fiyat",
  delivery_type: "Teslimat Tipi",
  fuel_policy: "Yakıt Politikası",
  driver_age: "Sürücü Yaşı",
  daily_range_limit: "Günlük KM Sınırı",
  car_id: "Araç Seçimi",
  turkish_currency: "Türk Lirası",
  american_currency: "Amerikan Doları",
  brand: "Marka",
  class: "Sınıf",
  size: "Boyut",
  gear: "Vites",
  fuel: "Yakıt",
  fuel_policy: "Yakıt Politikası",
  seat: "Koltuk",
  driver_age: "Sürücü Yaşı",
  km_limit: "KM Sınırı",
  unlimited: "Sınırsız",
  company: "Firma",
  phone: "Telefon",
  office: "Ofis",
  address: "Adres",
  daily_price: "Günlük Ücret",
  provision: "Depozit",
  total_payment_price: "Toplam Kira Bedeli",
  close: "Kapat",
  general: "Genel",
  age: "Yaş",
  driving_license_period: "Ehliyet süresi",
  yearly: "Yıllık",
  pickup: "Alış",
  return: "İade",
  day: "Gün",
  per_rental: "Kiralama Başına",
  loading: "YÜKLENİYOR ...",
  no_additional_products_found:
    "Bu firmaya ait ek ürün bulunamadı. Ek ürünleri şubeye gittiğinizde satın alabilirsiniz.",
  driver_info: "Sürücü Bilgileri",
  search_for_registered_driver: "Kayıtlı Sürücü Ara",
  search_user: "Kullanıcı Ara",
  remove: "Kaldır",
  name: "Ad",
  driver_name: "Sürücü Adı",
  driver_lastname: "Sürücü Soyadı",
  lastname: "Soyad",
  mobile_phone: "Cep Telefonu",
  email: "E-posta",
  date_of_birth: "Doğum Tarihi",
  tc_identity: "TC Kimlik",
  passport: "Pasaport",
  billing_information: "Fatura Bilgileri",
  reservation_date_change: "Rezervasyon Tarihi Değişikliği",
  billing_type: "Fatura Tipi",
  province: "İl",
  post_code: "Posta Kodu",
  tax_office: "Vergi Dairesi",
  tax_number: "Vergi Numarası",
  tax_tc: "Vergi / TC Nu",
  company_title: "Unvan",
  brand: "Marka",
  select_country: "Ülke Seçiniz",
  country: "Ülke",
  flight_number: "Uçuş Numarası",
  payment: "Ödeme",
  payment_status: "Ödeme Durumu",
  reservation_type: "Rezervayon Tipi",
  payment_type: "Ödeme Tipi",
  payment_date: "Ödeme Tarihi",
  rental_price: "Kiralama Ücreti",
  total_extras: "Toplam Ekstralar",

  drop_off_price: "Farklı Yere Bırakma Ücreti",
  office_service_fee: "Ofis Hizmet Ücreti",
  now_payment_price: "Şimdi Ödenecek Tutar",
  delivery_payment_price: "Teslim Alırken Ödenecek Tutar",
  total_payment_price2: "Toplam Ödenecek Tutar",
  excluding_deposit: "Depozito Hariç",

  deposit: "Depozito",
  cancel: "İptal",
  continue: "Devam Et",
  complete_the_reservation: "Rezervasyonu Tamamla",
  warning: "UYARI",
  warnin_info:
    "Bu rezervasyon fiyatı oranlar kısmında girilen yüzdeliğe göre hesaplanacaktır, onaylıyor musunuz ?",
  accept: "Kabul",
  phone_number: "Telefon Numarası",

  virtual_pos: "Sanal Pos",
  PayLink: "Link ile Ödeme",
  payment_via_sms_link: "SMS Linki İle Ödeme",
  payment_via_email_link: "Email Linki İle Ödeme",
  current_account: "Cari Hesap",

  individual: "Bireysel",
  individualCompany: "Şahıs Şirketi",
  company: "Firma",
  abroad: "Yabancı",
  abroadCompany: "Yabancı Şirket",

  name: "Ad",
  lastname: "Soyad",
  image: "Grsel",
  company_name: "Firma İsmi",
  campaign: "Kampanya",
  boarding_pass_obligation: "Uçuş Kartı Zorunluluğu",
  compulsory: "Zorunlu",
  compulsory_not: "Zorunlu Değil",
  daily: "Günlük",
  total: "Toplam",
  driving_license: "Ehliyet",
  delivery: "Teslimat",
  person: " Kişi",
  columns: "Sütunlar",
  filters: "Filtreler",
  enter_name_and_surname: "Ad soyad girin",
  enter_card_number: "Kart numarası girin",
  date: "Tarih",
  passive: "Pasif",

  pending: "Bekliyor",
  blocked: "Engellendi",
  status: "Durum",
  status_early_return: "Erken İade Durumu",
  identity_type: "Kimlik Tipi",
  okey: "Tamam",
  select_user: "Kullanıcıyı Seç",
  select_driver: "Sürücüyü Seç",
  copy: "Kopyala",
  find_car: "Araba Bul",
  not_found_user: "Kullanıcı Bulunamadı",
  users: "Kullanıcılar",
  wanted: "Aranan",
  user_info1: " isimli kullanıcı seçildi.",
  profil: "Profil",
  exit: "Çıkış",

  driver_form: "Sürücü Formu",
  delete_driver_form_info:
    "isimli kullanıcıyı kaldırıyorsunuz. Sürücü formunu da temizlemek ister misiniz?",
  yes: "Evet",
  not: "Hayır",
  drivers: "Sürücüler",
  drivers_not_found:
    " isimli kullanıcıya ait kayıtlı sürücü bulunamadı!reservation_success_info",
  selected_user: " isimli sürücü seçildi.",
  area: " alanı",
  check_the_form: "Formu kontrol edin",
  reservation_detail: "Rezervasyon Detayı",
  reservation_completed: "Rezervasyon Tamamlandı",
  go_to_reservation: "Rezervasyon'a Git",
  reservation_success_email_info:
    " kodlu rezervasyon sisteme eklendi ve rezervasyon detayı kullanıcı e-posta adresine gönderildi.",
  reservation_success_sms_info:
    " kodlu rezervasyon sisteme eklendi ve rezervasyon detayı kullanıcı telefon numarasına gönderildi.",
  reservation: "Rezervasyon",

  caution: "Dikkat!",

  exchange_rate: "Kur",

  sales: "Satış",
  discount: "İndirim",
  daily_rental_fee: "Günlük Kiralama Ücreti",
  retailDailyRentalPrice: "Perakende Günlük Ücreti",
  retailTotalRentalPrice: "Perakende Toplam Ücreti",
  total_extra_fee: "Toplam Ekstra Ücreti",
  reservation_currency: "Para Birimi",
  supplier_daily_currency_amount: "Tedarikçi Günlük Döviz Tutarı",
  supplier_total_currency_amount: "Tedarikçi Toplam Döviz Tutar",
  customer_daily_currency_amount: "Müşteri Günlük Döviz Tutarı",
  customer_total_currency_amount: "Müşteri Toplam Döviz Tutarı",

  amount_payable_on_delivery: "Teslimde Ödenecek Tutar",
  total_fee: "Toplam Ücret",
  identity: "Kimlik",

  years_old: "yaşında",
  type: "Tür",

  invoiced: "Faturalandı",
  error_t: "Hata",
  invoice_number: "Fatura No",
  invoice_date: "Fatura Tarihi",
  payment_info: "Ödeme Bilgileri",
  description_to_supplier: "Tedarikçiye Not",
  amount_paid: "Ödenen Tutar",
  advance: "Peşin",
  installment: "Taksitli",
  order_number: "Sipariş No",
  bank: "Banka",
  card_number: "Kart No",
  card_type: "Kart Tipi",
  local: "Yurt İçi",
  reservation_number: "Rezervasyon No",
  return_status: "İade Durumu",
  vendor_reservation_number: "Tedarikçi Rezervasyon No",
  search_date: "Arama Zamanı",
  process_date: "İşlem Zamanı",
  trader: "İşlem Yapan",
  cancel_date: "İptal Tarihi",
  cancel_time: "İptal Saati",
  cancel_author: "İptal Eden",
  rental_day_range: "Kiralama Gün Aralığı",
  survey_link: "Anket Link",

  reason_for_cancellation: "İptal Nedeni",
  send_reservation_email: "Rezervasyon Maili Gönder",
  forward_call_ivr: "Çağrıyı IVR Yönlendir",
  download_reservation_document: "Rezervasyon Belgesini İndir",
  early_return_request: "Erken İade Talebi",
  early_return_registration: "Erken İade Kaydı",
  early_return_rejection: "Erken İade Ret",
  refund_payment: "İade Ödemesi",
  noshow_refund_payment: "NoShow İade Ödemesi",
  refund_payment_amount: "İade Tutarı",
  partial_refund_fee_payment: "Kısmi Ücret İadesi",
  full_refund_fee_payment: "Tam Ücret İadesi",
  early_return_confirmation: "Erken İade Onay",
  send_survey: "Anket Gönder",
  res_date_change: "Rez. Tarihini Değiştir",
  cancel_btn: "İptal Et",
  change: "Değiştir",

  pay_with_virtual_pos: "Sanal Pos İle Öde",
  payments: "Ödemeler",
  channel: "Kanal",

  amount: "Tutar",
  early_return: "İade",
  collection: "Tahsilat",
  transferred: "Aktarıldı",
  early_return_btn: "İade Et",

  pos_transactions: "POS Hareketleri",
  process_t: "İşlem",
  message: "Mesaj",
  card: "Kart",
  agent_note: "Acente Notları",
  reservation_survey: "Rezervasyon Anketi",
  early_return_info: "Erken İade Bilgileri",
  returned_day: "İade Edilen Gün",
  returned_fee: "İade Edilen Ücret",
  returned_date: "İade Tarihi",

  date_of_registratio: "Kayıt Tarihi",

  type_of_early_returner: "Erken İade Edenin Tipi",
  early_return_author: "Erken İade Eden",

  early_return_reason: "Erken İade Nedeni",
  early_return_date: "Erken İade Tarihi",
  early_return_amount: "Erken İade Tutarı",
  early_return_description: "İade İşleminin Başlangıç Tarihini Seçiniz",

  the_fee_charged: "Alınan Ücret",
  rental_period: "Kiralama Süresi",
  financial_rejection_reason: "Finansal Red Neden",
  rejection_desc: "Lütfen iptal sebebinizi bizimle paylaşınız.",
  rejection_desc3: "Numaralı Rezervasyonu İptal Ediyorsunuz.",
  other: "Diğer",

  your_explanation: "Açıklamanız",
  leave: "Vazgeç",
  send: "Gönder",
  early_return_info2: "Numaralı Rezervasyonu Erken İade Ediyorsunuz.",
  early_return_info3: "Lütfen erken iade sebebinizi bizimle paylaşınız",
  early_return_form: "Erken İade Kayıt Formu",
  card_number2: "Kart Numarası",
  owner_of_the_card_number: "Kart Numarası Sahibi",
  month: "Ay",
  year: "Yıl",
  secure_code: "Güvenlik Kodu",

  complete_payment: "Ödemeyi Tamamla",
  reservation_settings: "Rezervasyon Ayarlar",
  reservation_type: "Rezervasyon Tipi",
  select_reservation_type: "Rezervasyon Tipi Seçiniz",
  spending_objection: "Harcama İtirazı",
  select_spending_objection: "Harcama İtirazı Seçiniz",
  update: "Güncelle",
  there_is: "Var",
  none: "Yok",
  invalid_parameters: "Geçersiz Parametreler",
  comments: "Yorumlar",
  comment_average: "Yorum Ortalaması",
  is_comment_sent: "Yorum Gönderildi Mi?",
  i_approve: "Onaylıyorum",
  early_return_confirmed: "Erken İade Onaylandı",
  refund_payment_confirmed: "İade Ödemesi Onaylandı",
  refund_payment_rejection: "İade Ödemesi Reddedildi",
  early_return_denied: "Erken İade Reddedildi",
  early_return_register_info: "Erken İade Kaydı başarılı bir şekilde alındı",
  approve_email_desc: "Onay E-postası başarılı bir şekilde gönderilmiştir",
  wait: "İşlem Yapılıyor Lütfen Bekleyin",
  cancel_success_info: "İptal işlemi başarılı bir şekilde gerçekleşti",
  search_t: "Arama...",
  notifications: "Bildirimler",
  send_notification: "Bildirim Gönder",
  fullCredit_billing_status: "Faturalanacak Full Credit Rez.",

  finished: "Tamamlandı",
  approved: "Gelecek",
  pending_t: "Son Aramalar",
  last_search: "Son Aramalar",
  active: "Devam Eden",
  completed: "Geçmiş",
  cancelled: "İptal Edilen",
  billed: "Faturası Kesilmiş",
  notbilled: "Faturalandırılmamış",
  canceledbill: "Faturası İptal Edilmiş",
  earlyReturnRequest: "Erken İade Talebi",
  earlyReturnRequestWaitConfirm: "Erken İade Onay Bekleyenler",
  confirmEarlReturn: "Onaylanan Erken İade",
  paymentError: "Ödeme Hatalı",
  all: "Hepsi",
  partner_payment: "Partner Ödeme",
  paid: "Ödendi",

  it_is_cancelled: "İptal Edildi",
  vendor: "Tedarikçi",
  user_type: "Kullanıcı Tipi",
  quest_member: "Misafir Üye",
  registered_member: "Kayıtlı Üye",
  unknown: "Bilinmiyor",
  reservation_date: "Tarih",
  reservation_hour: "Saat",

  error_reason: "Hata Nedeni",
  rental_days: "Gün",
  installment_count: "Taksit Sayısı",
  vendor_total_rental_price: "API Toplam Ücret",
  interest: "Vade Farkı",

  returned: "İade Edilen",
  purchase_date: "Alış Tarihi",
  invoice_status: "Fatura Durum",

  with_a_campaign: "Kampanyalı",
  campaign_name: "Kampanya İsmi",
  campaign_discount_rate: "Kampanya İndirim Oranı (%)",
  campaign_discount_price: "Kampanya İndirim Tutarı (TL)",
  campaign_vendor_commision_rate: "Kampanya Tedarikçi Komisyon Oranı (%)",
  delivery_office: "Alış Ofis",
  return_office: "İade Ofis",
  differen_returns: "Farklı İade mi",
  campaign_used_promo_code: "Kullanılan Promosyon Kodu",
  different_return_amount: "Drop Ücreti",
  operating_user_type: "İşlem Yapan Kullanıcı Tipi",
  driver_email: "Sürücü E-posta",
  driver_phone: "Sürücü Telefon",
  future_reservations: "Gelecek Rezervasyonlar",
  ongoing_reservations: "Devam Eden Rezervasyonlar",
  past_reservations: "Geçmiş Rezervasyonlar",

  canceled_reservations: "İptal Edilen Rezervasyonlar",
  invoiced_reservations: "Fatura Kesilmiş Rezervasyonlar",
  not_invoiced_reservations: "Faturalandırılmamış Rezervasyonlar",
  invoiced_cancel_reservations: "Faturası İptal Edilmiş Rezervasyonlar",
  noShowCancelled: "No-Show",

  early_return_requests: "Erken İade Talepleri",
  early_return_awaiting_approval: "Erken İade Onay Bekleyenler",
  early_return_success: "Erken İadesi Onaylanan Rezervasyonlar",
  condition_partner_paid: "Partner Ödendi İşartle",
  coming_soon: "Çok Yakında",
  find_a_car: "Araç bul",
  partners: "Partners",
  partner: "Partner",

  today: "Bugün",
  yesterday: "Dün",
  this_month: "Bu Ay",
  this_year: "Bu Yıl",
  last_month: "Geçen Ay",
  date: "Tarih",
  metropol_card_no: "Metropol Kart Numarası",
  metropol_card_no_eror: "Metropol Kart Numaranızı Kontrol Ediniz.",
  check_metropol_card_no: "Metropol Kart Rezervasyon",
  check_partner_rez: "Partner Panel Rezervasyon",
  invoices_transfer: "Fatura Aktarımı",
  payment_transfer: "Ödeme Aktarımı",
  city: "Şehir",
  contract: "Sözleşme",

  //sidebar
  reservationOfPartner: "Partner Adına Rezervasyon",
  sidebar: {
    search_nb: "Arama",
    home_page: "Ana Sayfa",
    make_a_reservation: "Rezervasyon Yap",
    make_a_reservation_site: "Rezervasyon Yap (Site)",
    all_reservations: "Tüm Rezervasyonlar",
    recent_searches: "Son Aramalar",
    continuning_reservations: "Devam Edenler",
    future_reservations: "Gelecekler",
    past_reservations: "Geçmişler",
    cancelled_reservations: "İptaller",
    no_show_cancelled_reservations: "No-Show İptalleri",
    payment_errors: "Ödeme Hatalılar",
    rates: "Oranlar",
    rates_desc: "...",
    rates_save: "Oranı Kaydet",
    rate_input_label: "Genel Fiyat Oranı",
    user: "Kullanıcılar",
    members: "Üyeler",
    managers: "Yöneticiler",
    partner_users: "Partner Kullanıcıları",
    company_users: "Firma Kullanıcıları",
    testers: "Test Kullanıcıları",
    mernis_control: "Mernis Kontrol",
    group_authorization: "Grup Yetkilendirmesi",
    account_deletion_requests: "Hesap Silme Talepleri",
    campanies: "Firmalar",
    campanies_location: "Firma Lokasyonları",
    campaines_cars: "Firma Araçları",
    reservations: "Rezervasyonlar",
    partners: "Partnerler",
    all: "Hepsi",
    last_search: "Son Aramalar",
    early_return_req: "Erken İade Talepleri",
    early_return_req_financial_confirm: "Erken İade (Bekliyor)",
    early_return_successed: "Erken İade (Onaylandı)",
    consensus: "Mutabakatlar",
    cars: "Araçlar",
    locations: "Lokasyonlar",
    city_locations: "Şehir Lokasyonları",
    comments: "Yorumlar",
    campaign: "Kampanyalar",
    coupons: "Kuponlar",
    send_notification: "Bildirim Gönder",
    settings: "Ayarlar",
    mss: "MSS",
    contracts: "Sözleşmeler",
    questions: "Sorular",
    partners_work: "İş Ortakları",
    montly_rental_page: "Aylık Kiralama Sayfası",
    accounting: "Muhasebe",
    invoices: "Faturalar",
    payments: "Ödemeler",
    categorys: "Kategoriler",
    questions: "Sorular",
  },
  //header
  header: {
    select_lang: "Dil Seçimi",
  },
  //dashboards
  dashboard: {
    supplier_based_reservations: "Tedarikçi Bazlı Rezervasyonlar",
    reservation_status: "Rezervasyon Durumları"
  },
  //alert
  alert: {
    success: "{name} güncelleme işlemi başarılı.",
    error: "{name} güncellenemedi.",
    no_records_found: "Kayıt bulunamadı",
    select_pickup: "Lütfen bir alış yeri seçiniz.",
    select_return: "Lütfen bir iade yeri seçiniz.",
    select_pickup_time: "Lütfen alış tarihi seçin.",
    select_return_time: "Lütfen iade tarihi seçin.",
    vendor_daily_price: "Lütfen Tedarikçi Günlük Fiyat Giriniz.",
    rental_daily_price: "Lütfen Müşteri Günlük Fiyat Giriniz.",
    delivery_type: "Lütfen Teslimat Tipi Seçiniz.",
    fuelPolicy: "Lütfen Yakıt Politikası Seçiniz.",
    driver_age: "Lütfen Sürücü Yaşını Kontrol Ediniz.",
    daily_range_limit_min_max:
      "Günlük KM Sınırı en az {min} en fazla {max} Değerlerini Kontrol Ediniz.",
    car_id: "Lütfen Araç Seçiniz.",
    select_pickup_date_warning: "Alış Tarihi en az,{date} olabilir.",
    select_pickup_date_warning2: "Alış Tarihi en fazla, {date} olabilir.",
    select_pickup_date_warning3:
      "Alış tarihi, iade tarihinden büyük ya da eşit olamaz!",
    metropol_card_no_eror: "Lütfen Metropol Kart Numaranızı Kontrol Ediniz.",
  },
  warning: {
    driver_empty: "Lütfen sürücü bilgilerinde boş alan bırakmayınız.",
    cancel_empty: "Lütfen iptal sebebi seçiniz.",
    payment_type_empty: "Lütfen Ödeme Tipi seçiniz!",
  },
  description: {
    spending_objection: "Bu rezervasyona harcama itirazı yapılmıştır.",
    spending_desc:
      "Bu teklif henüz rezervasyona dönüşmemiştir, rezervasyonu tamamlamak için ödeme yapabilirsiniz.",
  },
  vendorDescription: "Tedarikçiye Açıklama",
  generalDescription: "Açıklama",
  INVALID_PICKUP_DATE:
    "Seçilen alış tarihi, şuanki tarihten küçük olamaz!<br>Lütfen yeniden deneyin.",
  INVALID_DELIVERY_DATE:
    "Seçilen iade tarihi, alış tarihinden küçük ya da eşit olamaz!<br>Lütfen yeniden deneyin.",
  INVALID_PROMO_CODE: "Girilen promosyon kodu geçersizdir.",
  ARAMA_TALEBI_OLUSTURULMADI:
    "Arama talebi oluşturulamadı! Lütfen yeniden deneyin.",
  DEFAULT: "Lütfen yeniden deneyin.",
  INVALID_PROMO_DATES:
    "Girmiş olduğunuz promosyon kodu bu tarihler aralığında geçerli değildir",

  PARTNER_CANT_USE: "Bu promosyon kodunu kullanmak için yetkiniz bulunmuyor.",
  normal: "Normal",
  fullCredit: "Full Credit",
  payOnArrival: "Ofiste Öde",
  partialPayment: "Parçalı Öde",
  phoneFormatErr: "Telefon Doğrulama Hatası",
  apply: "Onayla",
  give_up: "Vazgeç",
  damage_exemption: "Hasar Güvencesi",
  theft_protection: "Theft Production",
  third_party_Coverage: "Third Party Coverage",
  unlimited_mileage: "Unlimited Mileage",
  free_cancellation: "Free Cancellation",
  free_damage_assistance: "Free Damage Assistance",
  EMPTY_OBJECT: "Lütfen en az bir arama kriteri giriniz.",
  tc_or_psp: "Kimlik No",
  vendor_choose: "Tedarikçi",
  campaigns: "Kampanyalar",
  reservation_date_range: "Rezervasyon Tarihi",
  vehicle_delivery_date_range: "Alış Tarihi",
  vehicle_return_date_range: "İade Tarihi",
  cancel_date_range: "İptal Tarihi",
  permissions: "İzinler",
  canceling_user_type: "İptal Eden Kullanıcı Tipi",
  accountingStatus: {
    approved: "Onaylandı, Fatura Bekliyor",
    pending: "Onay Bekliyor",
    timeout: "Zaman Aşımı",
    invoiced: "Faturalandı",
  },
  partnerTypes: {
    agent: "Acente",
    partner: "Partner",
    corporate: "Kurumsal",
    replacement: "İkame",
    overseasBroker: "Yurt Dışı Broker",
    domesticBroker: "Yurt İçi Broker",
  },
  profil_menu_t1: "Çıkış yapmak istiyor musunuz?",
  free_cancellation: "Ücretsiz İptal",
  free_cancel_text: "%{minHoursInfo} Saate Kadar",
  early_return_1: "Erken İade",
  free_cancel_alert:
    "İptal durumunda; araç alış zamanına %{minHoursInfo} saatten az bir süre varsa, %{payCutDay} günlük kira bedeli tahsil edilir.",
  early_return_alert:
    " %{vendorName} Firması Tarafından Erken İade Kabul Edilmemektedir.",
  free_cancel_alert_2:
    "İptal durumunda; araç alış zamanına %{minHoursInfo} saatten az bir süre varsa, rezervasyon ücretinin tamamı tahsil edilir.",
  available: "Var",
  no_show_cancel_alert:
    " %{vendorName} firması tarafından No-Show İptalinde Ücret İadesi Yapılmamaktadır",
  go_button: "GİT",
};
